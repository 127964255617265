<template>
  <div class="playlist_view bg-light py-3">
    <div class="playlist_hd">
      <h3>
        <span>{{ index }} / {{ videos.length }} </span>
        <small>{{ $t("courseVideos") }}</small>
      </h3>
      <div class="clearfix"></div>
    </div>
    <!--playlist_hd end-->
    <div class="clearfix"></div>
    <ul class="videos_lizt mCustomScrollbar" data-mcs-theme="dark">
      <li
        v-for="(item, i) in videos"
        :key="i"
        @click="$emit('click:video', item)"
      >
        <div class="vidz_row" :class="{ active: index === i + 1 }">
          <div>
            <i class="icon-play text-primary"></i>
          </div>
          <!--vidz_img end-->
          <div class="video_info">
            <p>
              <router-link :to="`video-${item.id}`" title="">{{
                item.description
              }}</router-link>
            </p>
            <span
              class="text-white badge badge-info"
              v-if="item.isPreview === 1"
              >{{ $t("previewVideo") }}</span
            >
            <div>
              <span
                class="text-white badge badge-success"
                v-if="item.interval > 0"
                >{{ $t("waitDayOfVideo", { day: item.interval }) }}</span
              >
            </div>
          </div>
          <div>
            <strong>
              <small> {{ convertM2H(item.duration) }}</small></strong
            >
          </div>
        </div>
        <!--vidz_row end-->
      </li>
    </ul>
    <!--videos_lizt end-->
  </div>
</template>

<script>
import { convertM2H } from "gurhan/helpers/Date";
export default {
  props: {
    videos: {
      default: () => [],
    },
    index: {
      default: 0,
    },
  },
  methods: {
    convertM2H,
  },
};
</script>

<style>
.vidz_row.active {
  background: rgba(255, 0, 0, 0.069);
}
</style>
