<template>
  <Modal
    v-model="editVideoSection"
    @ok="SEND_TO_UPDATE_SECTION"
    :title="$t('editVideo')"
  >
    <section class="vid-title-sec">
      <div class="container">
        <form>
          <div class="vid-title">
            <h2 class="title-hd">{{ $t("videoTitle") }}</h2>
            <div class="form_field">
              <input
                type="text"
                maxlength="100"
                required
                v-model="form.description"
                :placeholder="$t('addHere')"
              />
            </div>
            <div class="mt-4">
              <h2 class="title-hd">{{ $t("videoDescription") }}</h2>
              <vue-editor
                id="editor"
                :editorToolbar="[
                  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                  [{ size: ['small', false, 'large', 'huge'] }],
                  ['bold', 'italic', 'underline', 'strike'],
                  [
                    { align: '' },
                    { align: 'center' },
                    { align: 'right' },
                    { align: 'justify' },
                  ],
                  ['blockquote', 'code-block'],
                  [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                  [{ color: [] }, { background: [] }],
                  ['link'],
                  ['clean'],
                ]"
                v-model="form.overview"
              >
              </vue-editor>
            </div>
          </div>
          <div class="vid-title mt-4">
            <h2 class="title-hd">
              {{ $t("videoPreviewQuestion") }}
            </h2>
            <div class="bg-light card">
              {{ $t("ifSayYesPreviewBuyFree") }}
            </div>
            <div class="my-4">
              <button
                class="btn btn-pill"
                @click.prevent="form.isPreview = 1"
                :class="{ 'btn-success': form.isPreview === 1 }"
              >
                {{ $t("yes") }}
              </button>
              <button
                class="btn btn-pill"
                @click.prevent="form.isPreview = 0"
                :class="{ 'btn-success': form.isPreview === 0 }"
              >
                {{ $t("no") }}
              </button>
            </div>
          </div>
          <div class="vid-title mt-4">
            <h2 class="title-hd">
              {{ $t("whenVideoDoneShouldUserWait") }}
            </h2>
            <div class="my-4">
              <button
                class="btn btn-pill"
                @click.prevent="showInterval = true"
                :class="{ 'btn-success': showInterval }"
              >
                {{ $t("yes") }}
              </button>
              <button
                class="btn btn-pill"
                @click.prevent="showInterval = false"
                :class="{ 'btn-success': !showInterval }"
              >
                {{ $t("no") }}
              </button>
            </div>
            <h2 class="title-hd">
              {{ $t("howManyDaysUserWait") }}
            </h2>
            <div class="form_field" v-if="showInterval">
              <input
                type="number"
                :min="1"
                :max="30"
                required
                v-model="form.interval"
                :placeholder="$t('enterDays')"
              />
              <InvalidFeedback></InvalidFeedback>

              <div class="bg-light card mt-4">
                {{ $t("thisInfoWillShowbyUser") }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </section></Modal
  >
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
import { VueEditor } from "vue2-editor";

export default {
  components: { Modal, VueEditor },
  props: {
    video: {
      default: () => {},
    },
  },
  data() {
    return {
      editVideoSection: false,
      showInterval: false,
      form: { description: "", isPreview: 0, overview: "" },
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    editVideoSection(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "edit-video-description") {
          this.editVideoSection = true;
          this.form = { ...this.video };
        }
    },
    async SEND_TO_UPDATE_SECTION(loading) {
      loading(true);
      const response = await this.$api.put(`Videos/${this.video.id}`, {
        description: this.form.description,
        overview: this.form.overview,
        duration: this.video.duration,
        interval: this.showInterval ? this.form.interval : 0,
        isPreview: this.form.isPreview,
      });
      if (response.message === "OK") {
        this.editVideoSection = false;
        this.$emit("update:success");
      }
      loading(false);
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>

<style></style>
