<template>
  <VideoDetail></VideoDetail>
</template>

<script>
import VideoDetail from "../components/VideoDetail";
export default {
  components: { VideoDetail },
};
</script>

<style></style>
