<template>
  <div class="container">
    <h2 class="mt-4">{{ $t("quizes") }}</h2>
    <div class="d-flex justify-content-end">
      <button @click="showNewQuizModal = true" class="btn btn-pill donate my-3">
        {{ $t("newQuiz") }}
      </button>
    </div>
    <Table
      :count="count"
      no-search
      :loading="loading"
      :items="items"
      :headers="headers"
    >
      <template #status="{ item, index }">
        <router-link
          class="email-link"
          :to="$route.params.id + '/quizes/' + item.id"
        >
          <button class="btn m-1 btn-pill btn-xs donate mx-1">
            {{ $t("viewQuiz") }}
          </button>
        </router-link>
        <button
          :title="$t('update')"
          class="btn m-1 btn-pill btn-xs btn-warning mx-1"
          @click="SHOW_QUIZ_DETAIL(item)"
        >
          <img src="/images/edit.svg" width="20" alt="" />
        </button>
        <button
          :title="$t('remove')"
          @click="removeQuiz = { status: true, index, id: item.id }"
          class="btn m-1 btn-pill btn-xs btn-danger mx-1 btn-icon"
        >
          <img src="/images/trush.svg" width="20" alt="" />
        </button>
      </template>
      <template #name="{ item }">
        <router-link
          class="email-link"
          :to="$route.params.id + '/quizes/' + item.id"
        >
          {{ item.name }}
          <small
            class="badge"
            :class="`badge-${item.isActive === 1 ? 'success' : 'danger'}`"
            >{{ item.isActive === 1 ? $t("active") : $t("closed") }}</small
          >
          <div>
            <small class="text-muted">
              {{ item.description }}
            </small>
          </div>
        </router-link>
      </template>
      <template #createdAt="{ item }">
        {{ formatter(new Date(item.createdAt)) }}
      </template>
      <template #duration="{ item }">
        {{ item.duration }}{{ $t("min") }}
      </template>
    </Table>
    <div class="d-flex justify-content-center">
      <BoostrapPagination
        @change="SET_PAGINATION"
        :page="page"
        :pages="pages"
      ></BoostrapPagination>
    </div>
    <Modal hide-footer :title="$t('newQuiz')" v-model="showNewQuizModal">
      <QuizModal
        :product="product"
        :event="event"
        :video="video"
        :type="fileType"
        @refresh="REFRESH_COMPONENT"
        @close="showNewQuizModal = false"
      ></QuizModal>
    </Modal>
    <Modal hide-footer v-model="showEditQuizModal">
      <QuizModal
        :product="product"
        edit
        :key="editModalKey"
        :event="event"
        :video="video"
        :type="fileType"
        :item="selectedQuiz"
        @close="showEditQuizModal = false"
        @refresh="REFRESH_COMPONENT"
      ></QuizModal>
    </Modal>
    <Modal
      @ok="SET_REMOVE_FILE()"
      :okText="$t('remove')"
      persistent
      sm
      v-model="removeQuiz.status"
    >
      {{ $t("confirmQuizDeleteModalText") }}
    </Modal>
  </div>
</template>

<script>
import Table from "../Tables/index.vue";
import Quizes from "./index";
import BoostrapPagination from "gurhan/vue/BootstrapPagination.vue";

export default {
  mixins: [Quizes],
  components: { Table, BoostrapPagination },
  props: {
    type: {
      default: () => ({ typeTable: 1 }),
    },
    product: {
      default: () => {},
    },
    event: {
      default: () => {},
    },
    video: {
      default: () => {},
    },
  },
  data() {
    return {
      removeQuiz: {
        status: false,
        id: -1,
        index: -1,
      },
      count: 0,
      page: 1,
      editModalKey: 1,
      selectedQuiz: {},
      showNewQuizModal: false,
      showEditQuizModal: false,
      confirmDeleteModal: false,
      items: [],
      headers: [
        {
          title: this.$t("name"),
          value: "name",
          col: 6,
          md: 5,
          lg: 5,
          xl: 5,
        },
        {
          title: this.$t("createdAt"),
          value: "createdAt",
          col: 12,
          md: 3,
          lg: 3,
          xl: 3,
        },
        {
          title: this.$t("duration"),
          value: "duration",
          col: 12,
          md: 3,
          lg: 2,
          xl: 2,
        },
        {
          title: this.$t("situation"),
          value: "status",
          col: 12,
          md: 3,
          lg: 6,
          xl: 6,
        },
      ],
      loading: false,
    };
  },
  computed: {
    fileType() {
      const typeOneArr = [1, 2, 4];
      const typeTwoArr = [3];
      const typeThreeArr = [5];
      if (typeOneArr.includes(this.type.typeTable)) return 1;
      else if (typeTwoArr.includes(this.type.typeTable)) return 2;
      else if (typeThreeArr.includes(this.type.typeTable)) return 3;
      else return 2;
    },
    pages() {
      return Math.ceil(this.count / 10);
    },
  },
  created() {
    this.GET_PAGE_FROM_QUERY();
  },
  mounted() {
    this.SET_FILE_TYPE_BEFORE_GET_QUIZ();
  },
};
</script>

<style></style>
