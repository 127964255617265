<template>
  <Modal
    v-model="removeVideoModal"
    @ok="SEND_VIDEO_FOR_REMOVE"
    sm
    title="Videoyu Düzenleyin"
  >
    <section class="vid-title-sec">
      <div class="container">
        {{ $t("doYouWanttoRemoveVideo") }}
      </div>
    </section></Modal
  >
</template>

<script>
import Modal from "../Shared/Modal/index.vue";
export default {
  components: { Modal },
  data() {
    return {
      removeVideoModal: false,
    };
  },
  props: {
    video: {
      default: () => {},
    },
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.SET_CATEGORY_ACTION_FROM_QUERY();
      },
    },
    removeVideoModal(data) {
      if (!data)
        this.$router.replace({
          query: { ...this.$route.query, modal: "closed" },
        });
    },
  },
  methods: {
    SET_CATEGORY_ACTION_FROM_QUERY() {
      if (this.$route.query.modal)
        if (this.$route.query.modal === "remove-video")
          this.removeVideoModal = true;
    },
    async SEND_VIDEO_FOR_REMOVE(loading) {
      loading(true);
      const response = await this.$api.delete(`Videos/${this.video.id}`);
      if (response.message === "OK") {
        this.$emit("remove:success", this.video);
        this.removeVideoModal = false;
      }
      loading(false);
    },
  },
  created() {
    this.SET_CATEGORY_ACTION_FROM_QUERY();
  },
};
</script>

<style></style>
