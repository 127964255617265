import Modal from "../Shared/Modal/index.vue";
import QuizModal from "./QuizModal.vue";
import Alertbox from "../../mixins/AlertBox";
import { formatter } from "gurhan/helpers/Date";
export default {
  mixins: [Alertbox],
  components: { Modal, QuizModal },
  methods: {
    formatter,
    async SET_REMOVE_FILE(returnPath = "") {
      this.SET_LOADING_MODAL(true);
      const response = await this.$api.delete(`Quizzes/${this.removeQuiz.id}`);
      if (response.message === "OK" && response.result === "OK") {
        this.SET_ALERT_BOX("success", this.$t("updatedIsSucceed"));
        if (returnPath === "") this.items.splice(this.removeQuiz.index, 1);
        else this.$router.push(returnPath);
        this.removeQuiz.status = false;
      } else {
        this.SET_ALERT_BOX("danger", this.$t("updatedIsNotSucceed"));
      }
    },
    SET_PAGINATION(page) {
      this.page = page;
      this.$router.replace({ query: { ...this.$route.query, page } });
      this.GET_QUIZ_ITEMS(this.video.id);
    },
    REFRESH_COMPONENT(variant, content) {
      this.SET_ALERT_BOX(variant, content);
      this.showNewQuizModal = false;
      this.showEditQuizModal = false;
      this.SET_FILE_TYPE_BEFORE_GET_QUIZ();
    },
    SHOW_QUIZ_DETAIL(item) {
      this.selectedQuiz = item;
      this.editModalKey += 1;
      this.showEditQuizModal = true;
    },
    async GET_QUIZ_ITEMS(videoId) {
      this.loading = true;
      const response = await this.$api.get(
        `Quizzes/ByVideoId?videoId=${videoId}&page=${this.page}&limit=10`
      );
      if (response.message === "OK") {
        this.items = response.data.items;
        this.count = response.data.count;
      } else this.items = [];
      this.loading = false;
    },
    SET_FILE_TYPE_BEFORE_GET_QUIZ() {
      this.GET_QUIZ_ITEMS(this.video.id);
    },
    GET_PAGE_FROM_QUERY() {
      if (!isNaN(Number(this.$route.query?.page)))
        this.page = Number(this.$route.query?.page);
    },
  },
};
