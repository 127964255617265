<template>
  <section class="mn-sec full_wdth_single_video">
    <div class="container">
      <div class="row gap_remove align-items-center">
        <div class="col-lg-9">
          <div class="text-center" v-if="video.duration === 0">
            <h1 class="text-primary">
              <strong> {{ video.uploadPercent }}% </strong>
            </h1>
            <p>Videonuz hazırlanıyor..</p>
            <p>
              <small>
                Videonuz kısa bir süre içerisinde yayına alınacaktır.</small
              >
            </p>
            <div class="badge-light-info mt-5">
              <h4><strong>Videom neden hazırlanıyor?</strong></h4>
              <p>
                Videolarınızın güvenliğini arttırmak ve her ortamda
                izlenebilmesi için ayarlamalar yapıyoruz. Videonun uzunluğuna
                göre bu süre artabilir.
              </p>
            </div>
            <div class="badge-light-info mt-5">
              <p>Videolarınız hazırlanıyorken siteden ayrılabilirsiniz..</p>
            </div>
          </div>
          <Video
            :options="videoOptions"
            v-else-if="videoOptions.sources[0].src !== ''"
            :src="$api.image('/Videos/720/' + video.videoPath)"
            :videosLink="videosLink"
          ></Video>
          <!--vid-pr end-->
        </div>
        <div class="col-lg-3">
          <Playlist
            :getActiveUser="getActiveUser"
            :index="index"
            :videos="videos"
            @click:video="SET_VIDEO"
          ></Playlist>
          <!--playlist_view end-->
        </div>
      </div>
      <Detail
        @remove:success="SET_TO_REMOVE_VIDEO"
        @update:success="SET_INFO_FROM_PARAMS"
        :getActiveUser="getActiveUser"
        :video="video"
      ></Detail>
      <Tabs :fields="fields" :tab="tab" @change:tab="SET_TAB"></Tabs>
      <component
        :is="tab.path"
        v-if="video.id"
        :video="video"
        :type="{ typeTable: 5 }"
      ></component>
    </div>
  </section>
</template>

<script>
import Detail from "./Detail.vue";
import Tabs from "./Tabs.vue";
import Playlist from "./Playlist.vue";
import Video from "./Video.vue";
import Quizes from "../Quizes/index.vue";
import Assignments from "../Assignments/index.vue";
export default {
  components: { Detail, Playlist, Video, Assignments, Tabs, Quizes },
  data() {
    return {
      video: { videoPath: "", description: "" },
      section: {},
      videoOptions: {
        live: true,
        autoplay: true,
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        sources: [
          {
            src: "",
            type: "application/x-mpegURL",
          },
        ],
      },
      videos: [],
      tab: {},
      videosLink: {
        link: "",
        token: "",
      },
      fields: [
        { title: this.$t("uploadedItems"), path: "Assignments" },
        { title: this.$t("quizes"), path: "Quizes" },
      ],
    };
  },
  computed: {
    index() {
      return this.videos.findIndex((v) => v.id === this.video.id) + 1;
    },
    getActiveUser() {
      return this.$store.state.User.activeUser;
    },
  },
  watch: {
    video: {
      deep: true,
      async handler(data) {
        this.videoOptions.sources[0].src = "";
        if (data.videoPath !== "") {
          const videoSlashLength = data.videoPath.split("/");
          console.log(data.videoPath, "video path", videoSlashLength);
          if (videoSlashLength.length > 1) {
            const url = await this.$api.get(
              `Videos/GetCDNStream/${this.video.id}`
            );
            console.log(url, "urllll");
            if (url.message === "OK") {
              this.videosLink = url.data;
              this.videoOptions.sources[0].src = url.data.link;
            }
          } else {
            this.videoOptions.sources[0].src = this.$api.image(
              `Videos/GetStream/${this.video.id}/720/part.m3u8`
            );
          }
        }
      },
    },
  },
  methods: {
    SET_VIDEO(data) {
      this.video = { videoPath: "" };
      setTimeout(() => {
        this.video = data;
      }, 250);
    },
    async SET_INFO_FROM_PARAMS() {
      this.videoOptions.sources[0].src = "";
      const response = await this.$api.get(
        `Videos?sectionId=${this.$route.params.sectionId}`
      );
      if (response.message === "OK") {
        this.videos = response.data;
        const id = this.$route.params.id.split("-")[1];
        this.video = response.data.find((data) => data.id === Number(id));
        if (this.video === undefined) this.$router.push("/error");
        else {
          this.videosLink = {
            link: this.$api.image(`Videos/GetStream/${id}/720/part.m3u8`),
            token: "",
          };
        }
        this.GET_ACTIVE_TAB();
      }
    },
    GET_ACTIVE_TAB() {
      const query = this.$route.query;
      this.tab = this.fields.find((field) => field.path === query.tab) || {
        title: this.$t("uploadedItems"),
        path: "Assignments",
      };
    },
    SET_TO_REMOVE_VIDEO(e) {
      const index = this.videos.findIndex((video) => video.id === e.id);
      if (index !== -1 && index + 1 === this.videos.length) {
        this.SET_VIDEO(this.videos[0]);
        this.$router.replace(`video-${this.videos[0].id}`);
        this.videos.splice(index, 1);
      } else if (index !== -1) {
        this.$router.replace(`video-${this.videos[index + 1].id}`);
        this.SET_VIDEO(this.videos[index + 1]);
        this.videos.splice(index, 1);
      } else this.$router.push("/");
    },
    SET_TAB(tab) {
      this.tab = tab;
      this.$router.push({ query: { ...this.$route.query, tab: tab.path } });
    },
  },
  mounted() {
    this.SET_INFO_FROM_PARAMS();
  },
};
</script>

<style></style>
